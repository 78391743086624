import React, { useEffect, useState, useRef } from 'react'
// import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { DeleteForeverRounded, EditRounded } from '@material-ui/icons'
import { Box, Button } from '@material-ui/core'

import LoadingSkeleton from '@objects/loadingSkeleton'
import Table from '@objects/table'
import useDialog from '@hooks/useDialog'
import useSnackbar from '@hooks/useSnackbar'
import {
  getDialogNotificationRecipients,
  deleteDialogNotificationRecipients,
} from '@services/dialogrecipientservice'

const tableHeader = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'actions', label: 'Aktionen' },
]

function DialogRecipientTable() {
  const isMountedRef = useRef(null)
  const dial = useDialog()
  const [editorList, setEditorList] = useState([])
  const { toggleSnackbar } = useSnackbar()
  const tableActions = [
    {
      title: 'Bearbeiten',
      icon: <EditRounded />,
      onClick: (row) => {
        dial.openDialog('editdialogrecipient', {
          onOk: refreshEditorList,
          recipientid: row.id,
          name: row.items[0].label,
          email: row.items[1].label,
        })
      },
    },
    {
      title: 'Löschen',
      icon: <DeleteForeverRounded />,
      onClick: (row) => {
        deleteDialogNotificationRecipients(row.id).then(() =>
          refreshEditorList()
        )
      },
    },
  ]

  useEffect(() => {
    isMountedRef.current = true
    refreshEditorList()
    return () => {
      isMountedRef.current = false
    }
  }, [])

  function refreshEditorList() {
    setEditorList([])
    getDialogNotificationRecipients()
      .then((editors) => {
        if (isMountedRef.current) {
          setEditorList(
            editors.map((editor) => ({
              id: editor.id,
              hideActions: editor.isSuperuser,
              items: [{ label: editor.name }, { label: editor.email }],
            }))
          )
        }
      })
      .catch((e) => {
        console.error(e)
        toggleSnackbar(
          `Bitte wenden Sie sich an einen Administrator - ${e}`,
          'error'
        )
      })
  }

  return (
    <Box mt={8}>
      <LoadingSkeleton type="table">
        <Table heads={tableHeader} rows={editorList} actions={tableActions} />
        <Box mb={8} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() =>
            dial.openDialog('adddialogrecipient', {
              onOk: refreshEditorList,
            })
          }
        >
          <FormattedMessage id="button.neworderrecipient" />
        </Button>
      </LoadingSkeleton>
    </Box>
  )
}

DialogRecipientTable.propTypes = {}

export default DialogRecipientTable
