import React from 'react'
import PropTypes from 'prop-types'
import DialogRecipientTable from '@components/dialogrecipienttable/index'

export const frontmatter = {
  title: 'Beiträge - E-Mail Einstellung',
}

function DialogRecipientPage() {
  return <DialogRecipientTable />
}

DialogRecipientPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default DialogRecipientPage
